.App {
  max-width: 900px;
  margin: auto;
}

.Article {
  margin: 100px 30px;
}

.Article p {
  line-height: 30px;
}

.Article h2 {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  min-height: 150px;
}

.Banner h2 {
  font-size: 10px;
  word-wrap: break-word;
  margin: auto;
}

.Box {
  margin: auto;
}